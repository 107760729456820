import React from 'react';

export const Nav00DataSource = {
  wrapper: { className: 'header0 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header0-logo',
    children: 'https://apps.nexarcore.com/img/nexarcoreAppsLogoTransparent.png',
  },
  Menu: {
    className: 'header0-menu',
    children: [
      {
        name: 'item0',
        className: 'header0-item',
        children: {
          href: '#',
          children: [{ children: 'Account', name: 'text' }],
        },
        subItem: [
          {
            name: 'sub0',
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://gw.alipayobjects.com/zos/rmsportal/ruHbkzzMKShUpDYMEmHM.svg',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: 'Ant Design',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: 'Login',
                },
              ],
            },
          },
          {
            name: 'sub1',
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://gw.alipayobjects.com/zos/rmsportal/ruHbkzzMKShUpDYMEmHM.svg',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: 'Ant Design',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: 'Account',
                },
              ],
            },
          },
        ],
      },
      {
        name: 'item1',
        className: 'header0-item',
        children: {
          href: '#',
          children: [{ children: 'Latest', name: 'text' }],
        },
      },
      {
        name: 'item2',
        className: 'header0-item',
        children: {
          href: '#',
          children: [{ children: 'Dashboard', name: 'text' }],
        },
      },
      {
        name: 'item3',
        className: 'header0-item',
        children: {
          href: '#',
          children: [{ children: 'All Games', name: 'text' }],
        },
      },
    ],
  },
  mobileMenu: { className: 'header0-mobile-menu' },
};
export const Banner01DataSource = {
  wrapper: { className: 'banner0' },
  textWrapper: { className: 'banner0-text-wrapper' },
  title: {
    className: 'banner0-title',
    children: 'https://apps.nexarcore.com/img/nexarcoreAppsLogoTransparent.png',
  },
  content: {
    className: 'banner0-content',
    children: 'NexarCore Game Center',
  },
  button: { className: 'banner0-button', children: 'Learn More' },
};
export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: 'Top Games' }],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              href: 'quiz',
              children:
                'https://apps.nexarcore.com/img/quiz.jpg',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: 'NexarCore Quiz',
            },
            { name: 'content', children: 'Play quizzes and earn stars in multiplayer mode' },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              href: '3d',
              children:
                'https://apps.nexarcore.com/img/pic1.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: 'Virtual 3D App',
            },
            {
              name: 'content',
              children: 'Join the virtual world of NexarCore!',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              href: 'mayhem',
              children:
                'https://apps.nexarcore.com/img/pic4.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: 'Mayhem',
            },
            {
              name: 'content',
              children: 'Play the Mayhem board game online with as many people as you like',
            },
          ],
        },
      },
    ],
  },
};
export const Content50DataSource = {
  wrapper: { className: 'home-page-wrapper content5-wrapper' },
  page: { className: 'home-page content5' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: 'Latest Games', className: 'title-h1' },
      {
        name: 'content',
        className: 'title-content',
        children: 'A selection of the newest games',
      },
    ],
  },
  block: {
    className: 'content5-img-wrapper',
    gutter: 16,
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content', href: 'quiz' },
          img: {
            children:
              'https://apps.nexarcore.com/img/quiz.jpg',
          },
          href: 'quiz',
          content: { children: 'NexarCore Quiz' },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content', href: '3d' },
          img: {
            children:
              'https://apps.nexarcore.com/img/pic1.png',
          },
          href: '3d',
          content: { children: 'NexarCore Virtual reality' },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content', href: 'simbal' },
          img: {
            children:
              'https://apps.nexarcore.com/img/epicSimbal.jpg',
          },
          href: 'simbal',
          content: { children: 'Simbal - an ordinary game' },
        },
      },
      {
        name: 'block3',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' , href: 'mayhem'},
          img: {
            children:
              'https://apps.nexarcore.com/img/pic4.png',
          },
          content: { children: 'Mayhem - online board game' },
        },
      },
    ],
  },
};
export const Content30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: 'Scoreboard',
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: 'Users with the most stars',
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'Superhoodie' },
          content: {
            className: 'content3-content',
            children:
              'Mostly in Virtual reality',
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'Lisha' },
          content: {
            className: 'content3-content',
            children:
              'NexarCore Quiz',
          },
        },
      },
      /*{
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '云监控' },
          content: {
            className: 'content3-content',
            children:
              '分布式云环境集中监控，统一资源及应用状态视图，智能分析及故障定位。',
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '移动' },
          content: {
            className: 'content3-content',
            children:
              '一站式移动金融APP开发及全面监控；丰富可用组件，动态发布和故障热修复。',
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '分布式中间件' },
          content: {
            className: 'content3-content',
            children:
              '金融级联机交易处理中间件，大规模分布式计算机，数万笔/秒级并发能力，严格保证交易数据统一性。',
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '大数据' },
          content: {
            className: 'content3-content',
            children:
              '一站式、全周期大数据协同工作平台，PB级数据处理、毫秒级数据分析工具。',
          },
        },
      },*/
    ],
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo',
          children: 'https://apps.nexarcore.com/img/nexarcoreAppsLogoTransparent.png',
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: 'Powered by NexarCore',
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: 'CME' },
        childWrapper: {
          children: [
            { name: 'link0', href: 'https://nexarcore.com/cme', children: 'Messaging & Chatrooms' },
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: 'Babybot' },
        childWrapper: {
          children: [
            { href: 'https://nexarcore.com/babybot', name: 'link0', children: 'Artificial Intelligence' },
          ],
        },
      },
      {
        name: 'block3',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: 'Cloud' },
        childWrapper: {
          children: [
            { href: 'https://cloud.nexarcore.com', name: 'link0', children: 'Data Storage & Sharing' },
          ],
        },
      },
      {
          name: 'block0',
          xs: 24,
          md: 6,
          className: 'block',
          title: {},
          childWrapper: { children: []}
      },
      {
          name: 'block4',
          xs: 24,
          md: 6,
          className: 'block',
          title: { children: 'Stories / Docs' },
          childWrapper: {
              children: [
                  { name: 'link0', href: 'https://nexarcore.com/stories', children: 'Publish & Enjoy Articles' },
                  { name: 'link0', href: 'https://nexarcore.com/docs', children: 'Generate Documents & CVs' },
              ],
          },
      },
      {
          name: 'block5',
          xs: 24,
          md: 6,
          className: 'block',
          title: { children: 'Games' },
          childWrapper: {
              children: [
                  { name: 'link0', href: 'https://apps.nexarcore.com', children: 'Online Multiplayer Games' },
              ],
          },
      },
      {
          name: 'block6',
          xs: 24,
          md: 6,
          className: 'block',
          title: { children: 'Contact' },
          childWrapper: {
              children: [
                  { name: 'link0', href: 'https://nexarcore.com/contact.php', children: 'Help & Customer Support' },
              ],
          },
      },
    ],
  },

copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
          ©{new Date().getFullYear()} by <a href="https://nexarcore.com">NexarCore</a> All Rights
        Reserved
      </span>
    ),
  },
};
